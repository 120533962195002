@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body, #root {
  width: 100vw;
  max-width: 100vw;

  height: 100vh;
  max-height: 100%;

  overflow: hidden;
}

@keyframes shake {
  0% { transform: translate(0); }
  20% { transform: translate(2em); }
  40% { transform: translate(-2em); }
  60% { transform: translate(2em); }
  80% { transform: translate(-2em); }
  100% { transform: translate(0); }
}
